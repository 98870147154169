import { Box, Skeleton, Typography } from '@mui/material'
import { CheckboxCustom } from 'components/Checkbox'
import React, { FC } from 'react'

import { Props } from './FiltersItem.types'

export const FiltersItem: FC<Props> = ({
  label,
  withAdditionalValue = false,
  additionalValue,
  checked,
  onChange,
  isPartiallyChecked,
  disabled,
  loading,
}) => {
  return loading ? (
    <Skeleton variant={'text'}>
      <CheckboxCustom label={label} />
    </Skeleton>
  ) : (
    <Box
      onClick={e => e.stopPropagation()}
      sx={{ minHeight: '40px', display: 'flex', alignItems: 'flex-start' }}
    >
      <CheckboxCustom
        disabled={disabled}
        label={
          <>
            {label}{' '}
            {withAdditionalValue && (
              <Typography
                component={'span'}
                fontSize={15}
                sx={{
                  pt: '8px',
                  color: 'grey.400',
                  lineHeight: '24px',
                }}
              >
                ({additionalValue || 0})
              </Typography>
            )}
          </>
        }
        checked={checked}
        onChange={onChange}
        sx={{
          mr: '8px',
          ml: '-9px',
          alignItems: 'flex-start',
          '.MuiTypography-root': {
            pt: '8px',
            fontSize: '16px',
          },
          '& .MuiCheckbox-root.Mui-checked': {
            '& > span:first-of-type': {
              backgroundColor: isPartiallyChecked ? '#D99C00' : '#009970',
            },
          },
        }}
      />
    </Box>
  )
}
