import { Box, Button, Divider, Skeleton, Typography } from '@mui/material'
import { FiltersItem } from 'components/FiltersItem'
import { FiltersItemParent } from 'components/FiltersItemParent'
import { FiltersTitle } from 'components/FiltersTitle'
import { capitalize } from 'lodash'
import React, { FC, useState } from 'react'
import { AuctionCategory } from 'types/auction'

import { Props } from './Filters.types'

export const Filters: FC<Props> = ({
  categories,
  locations,
  manufacturers,
  lotSizes,
  conditions,
  statuses,
  favoriteCategories,
  onCategorySelect,
  onSubCategorySelect,
  onConditionsSelect,
  onLocationsSelect,
  onStatusesSelect,
  onLotSizesSelect,
  favoriteLocations,
  favoriteManufacturers,
  favoriteConditions,
  favoriteStatuses,
  favoriteLotSizes,
  onManufacturersSelect,
  isShowMore,
  onRestCategoriesSelect,
  onRestManufacturersSelect,
  onRestConditionsSelect,
  withDisabled,
  withAdditionalValue = false,
  loading,
  onResetPage,
  isSorted = true,
}) => {
  const [showMoreCategories, setShowMoreCategories] = useState(false)
  const [showMoreLocations, setShowMoreLocations] = useState(false)
  const [showMoreManufacturers, setShowMoreManufacturers] = useState(false)
  const [showMoreLotSizes, setShowMoreLotSizes] = useState(false)
  const [showMoreConditions, setShowMoreConditions] = useState(false)

  const lotSizesData = lotSizes && Object.entries(lotSizes)

  const categoriesList = categories
    ? isShowMore
      ? [...categories].splice(0, showMoreCategories ? 30 : 5)
      : categories
    : []
  const restCategories = categories
    ? [...categories].splice(30, categories?.length - 30).map(({ id }) => id)
    : []

  const locationsList = locations
    ? isShowMore
      ? [...locations].splice(0, showMoreLocations ? 30 : 5)
      : locations
    : []

  const manufacturersList = manufacturers
    ? isShowMore
      ? [...manufacturers].splice(0, showMoreManufacturers ? 30 : 5)
      : manufacturers
    : []

  const lotSizesList = lotSizesData
    ? isShowMore
      ? [...lotSizesData].splice(0, showMoreLotSizes ? 30 : 5)
      : lotSizesData
    : []

  const conditionsList = conditions
    ? isShowMore
      ? [...conditions].splice(0, showMoreConditions ? 30 : 5)
      : conditions
    : []
  const restConditions = conditions
    ? [...conditions].splice(30, conditions?.length - 30).map(({ id }) => id)
    : []

  const openTotal = statuses?.reduce((total, { auctions, status }) => {
    if (status === 'active' || status === 'awaiting') {
      return total + auctions.total
    }
    return total
  }, 0)
  const soldTotal =
    statuses?.find(({ status }) => status === 'sold')?.auctions?.total || 0
  const closedTotal =
    statuses?.find(({ status }) => status === 'closed')?.auctions?.total || 0

  const statusesList = [
    { status: 'open', auctions: { total: openTotal } },
    { status: 'sold', auctions: { total: soldTotal } },
    { status: 'closed', auctions: { total: closedTotal } },
  ]

  const createLotSizeTitle = (title: string) => {
    switch (title) {
      case 'single_pallet':
        return '1 pallet'
      case 'two_pallets':
        return '2 pallets'
      case 'three_pallets':
        return '3 pallets'
      case 'gt_three_pallets':
        return '> 3 pallets'
      default:
        return capitalize(title.split('_').join(' '))
    }
  }

  const renderCategoryItem = ({
    id: parentId,
    subCategories,
    auctions,
    value,
  }: AuctionCategory) => {
    const subCategoriesList = subCategories ?? []
    const subCategoriesIds = subCategoriesList?.map(({ id }) => id) || []
    const checkedSubCategories =
      subCategoriesList
        ?.filter(({ id }) => favoriteCategories?.includes(id))
        ?.map(({ id }) => id) || []

    const isPartiallyChecked =
      !!checkedSubCategories?.length &&
      checkedSubCategories?.length < subCategoriesList?.length

    return (
      <FiltersItemParent
        disabled={!auctions && withDisabled}
        checked={favoriteCategories?.includes(parentId) || isPartiallyChecked}
        onChange={() => {
          onCategorySelect?.(parentId, subCategoriesIds)
          onResetPage?.()
        }}
        isPartiallyChecked={isPartiallyChecked}
        key={parentId}
        id={parentId.toString()}
        label={value}
        additionalValue={auctions}
        hiddenIcon={Boolean(!subCategoriesList.length)}
        loading={loading}
        withAdditionalValue={withAdditionalValue}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: 3,
          }}
        >
          {subCategoriesList?.map(({ value, auctions, id }) => (
            <FiltersItem
              disabled={!auctions && withDisabled}
              checked={
                favoriteCategories?.includes(id) ||
                favoriteCategories?.includes(parentId)
              }
              onChange={() => {
                onSubCategorySelect?.(
                  id,
                  parentId,
                  subCategoriesIds,
                  checkedSubCategories
                )
                onResetPage?.()
              }}
              key={id}
              label={capitalize(value)}
              withAdditionalValue={withAdditionalValue}
              additionalValue={auctions}
            />
          ))}
        </Box>
      </FiltersItemParent>
    )
  }

  const selectedCategoriesList = categoriesList?.filter(
    category =>
      favoriteCategories?.includes(category.id) ||
      category.subCategories.filter(item =>
        favoriteCategories?.includes(item.id)
      )?.length
  )

  const filteredCategoriesList = categoriesList?.filter(
    category =>
      !favoriteCategories?.includes(category.id) &&
      category.subCategories.filter(item =>
        favoriteCategories?.includes(item.id)
      )?.length === 0
  )

  return (
    <>
      <Box className='selected-items' sx={{ m: '24px 0 26px' }}>
        {!!categories?.length && (
          <>
            {!isSorted ? (
              <>{categoriesList.map(renderCategoryItem)}</>
            ) : (
              <>
                {selectedCategoriesList.map(renderCategoryItem)}
                {Boolean(favoriteCategories?.length) &&
                  Boolean(filteredCategoriesList?.length) && (
                    <Divider flexItem sx={{ m: '8px 0 ' }} />
                  )}
                {filteredCategoriesList.map(renderCategoryItem)}
              </>
            )}

            {isShowMore && showMoreCategories && !!restCategories?.length && (
              <Box sx={{ pl: '28px' }}>
                <FiltersItem
                  withAdditionalValue={withAdditionalValue}
                  checked={favoriteCategories?.includes(restCategories[0])}
                  onChange={() => {
                    onRestCategoriesSelect?.(restCategories)
                    onResetPage?.()
                  }}
                  label='Other'
                />
              </Box>
            )}

            {isShowMore && showMoreCategories && !!restCategories?.length && (
              <Box sx={{ pl: '28px' }}>
                <FiltersItem
                  withAdditionalValue={withAdditionalValue}
                  checked={favoriteCategories?.includes(restCategories[0])}
                  onChange={() => {
                    onRestCategoriesSelect?.(restCategories)
                    onResetPage?.()
                  }}
                  label='Other'
                />
              </Box>
            )}

            {isShowMore &&
              categories?.length > 5 &&
              (loading ? (
                <Skeleton variant={'text'}>
                  <Typography>Show more</Typography>
                </Skeleton>
              ) : (
                <Button
                  onClick={() => setShowMoreCategories(!showMoreCategories)}
                  aria-label='show more'
                  sx={{
                    p: 0,
                    mt: '9px',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {showMoreCategories ? 'Show less' : 'Show more'}
                </Button>
              ))}
          </>
        )}
      </Box>

      {!!locations?.length && (
        <Box sx={{ m: '24px 0 26px' }}>
          <FiltersTitle title='Location' loading={loading} />
          {locationsList.map(({ state, auctions }) => (
            <FiltersItem
              checked={favoriteLocations?.includes(state)}
              onChange={() => {
                onLocationsSelect?.(state)
                onResetPage?.()
              }}
              key={state}
              label={state}
              disabled={!auctions.total && withDisabled}
              additionalValue={auctions.total}
              withAdditionalValue={withAdditionalValue}
              loading={loading}
            />
          ))}

          {isShowMore &&
            locations?.length > 5 &&
            (loading ? (
              <Skeleton variant={'text'}>
                <Typography>Show more</Typography>
              </Skeleton>
            ) : (
              <Button
                onClick={() => setShowMoreLocations(!showMoreLocations)}
                aria-label='show more'
                sx={{
                  p: 0,
                  mt: '9px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {showMoreLocations ? 'Show less' : 'Show more'}
              </Button>
            ))}
        </Box>
      )}

      {!!manufacturers?.length && (
        <Box sx={{ m: '24px 0 26px' }}>
          <FiltersTitle title='Manufacturer' loading={loading} />
          {manufacturersList.map(({ value, id, auctions, ids }) => (
            <FiltersItem
              checked={
                value === 'others'
                  ? ids && favoriteManufacturers?.includes(ids[0])
                  : favoriteManufacturers?.includes(id)
              }
              onChange={() => {
                if (value === 'others' && ids) {
                  onRestManufacturersSelect?.(ids)
                } else {
                  onManufacturersSelect?.(id)
                }
                onResetPage?.()
              }}
              withAdditionalValue={withAdditionalValue}
              additionalValue={auctions?.total}
              disabled={!auctions?.total && withDisabled}
              key={id}
              label={value === 'others' ? capitalize(value) : value}
              loading={loading}
            />
          ))}

          {isShowMore &&
            manufacturers?.length > 5 &&
            (loading ? (
              <Skeleton variant={'text'}>
                <Typography>Show more</Typography>
              </Skeleton>
            ) : (
              <Button
                onClick={() => setShowMoreManufacturers(!showMoreManufacturers)}
                aria-label='show more'
                sx={{
                  p: 0,
                  mt: '9px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {showMoreManufacturers ? 'Show less' : 'Show more'}
              </Button>
            ))}
        </Box>
      )}

      {!!lotSizesData?.length && (
        <Box sx={{ m: '24px 0 26px' }}>
          <FiltersTitle title='Lot Size' loading={loading} />
          {lotSizesList.map(([key, value]) => (
            <FiltersItem
              key={key}
              label={createLotSizeTitle(key)}
              checked={favoriteLotSizes?.includes(key)}
              onChange={() => {
                onLotSizesSelect?.(key)
                onResetPage?.()
              }}
              disabled={value <= 0 && withDisabled}
              withAdditionalValue={withAdditionalValue}
              additionalValue={value}
              loading={loading}
            />
          ))}

          {isShowMore &&
            lotSizesData?.length > 5 &&
            (loading ? (
              <Skeleton variant={'text'}>
                <Typography>Show more</Typography>
              </Skeleton>
            ) : (
              <Button
                onClick={() => setShowMoreLotSizes(!showMoreLotSizes)}
                aria-label='show more'
                sx={{
                  p: 0,
                  mt: '9px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {showMoreLotSizes ? 'Show less' : 'Show more'}
              </Button>
            ))}
        </Box>
      )}

      {!!conditions?.length && (
        <Box sx={{ m: '24px 0 26px' }}>
          <FiltersTitle title='Condition' loading={loading} />
          {conditionsList.map(({ name, auctions, id }) => (
            <FiltersItem
              checked={favoriteConditions?.includes(id)}
              onChange={() => {
                onConditionsSelect?.(id)
                onResetPage?.()
              }}
              key={id}
              label={name}
              disabled={!auctions.total && withDisabled}
              withAdditionalValue={withAdditionalValue}
              additionalValue={auctions.total}
              loading={loading}
            />
          ))}
          {isShowMore && showMoreConditions && !!restConditions?.length && (
            <FiltersItem
              withAdditionalValue={withAdditionalValue}
              checked={favoriteConditions?.includes(restConditions[0])}
              onChange={() => {
                onRestConditionsSelect?.(restConditions)
                onResetPage?.()
              }}
              label='Other'
            />
          )}
          {isShowMore &&
            conditions?.length > 5 &&
            (loading ? (
              <Skeleton variant={'text'}>
                <Typography>Show more</Typography>
              </Skeleton>
            ) : (
              <Button
                onClick={() => setShowMoreConditions(!showMoreConditions)}
                aria-label='show more'
                sx={{
                  p: 0,
                  mt: '9px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {showMoreConditions ? 'Show less' : 'Show more'}
              </Button>
            ))}
        </Box>
      )}
      {!!statuses?.length && (
        <Box sx={{ m: '24px 0 26px' }}>
          <FiltersTitle title='Auction Status' loading={loading} />
          {statusesList.map(({ status, auctions }) => (
            <FiltersItem
              checked={
                status === 'open'
                  ? favoriteStatuses?.includes('active') &&
                    favoriteStatuses?.includes('awaiting')
                  : favoriteStatuses?.includes(status)
              }
              onChange={() => {
                onStatusesSelect?.(status)
                onResetPage?.()
              }}
              key={status}
              label={capitalize(status)}
              disabled={!auctions.total && withDisabled}
              withAdditionalValue={withAdditionalValue}
              additionalValue={auctions.total}
              loading={loading}
            />
          ))}
        </Box>
      )}
    </>
  )
}
