import { Skeleton, Typography } from '@mui/material'
import React, { FC } from 'react'

import { Props } from './FiltersTitle.types'

export const FiltersTitle: FC<Props> = ({ title, loading }) => {
  return loading ? (
    <Skeleton
      variant={'text'}
      sx={{
        mb: '16px',
      }}
    >
      <Typography>{title}</Typography>
    </Skeleton>
  ) : (
    <Typography
      variant={'body2'}
      fontWeight={600}
      sx={{
        textTransform: 'uppercase',
        color: 'grey.500',
        mb: '16px',
      }}
    >
      {title}
    </Typography>
  )
}
