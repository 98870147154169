import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
} from '@mui/material'
import { FiltersItem } from 'components/FiltersItem'
import { SimpleArrowDownwardIcon } from 'icons'
import React, { FC, PropsWithChildren } from 'react'

import { Props } from './FiltersItemParent.types'

export const FiltersItemParent: FC<PropsWithChildren<Props>> = ({
  id,
  label,
  additionalValue,
  checked,
  onChange,
  children,
  isPartiallyChecked,
  disabled,
  hiddenIcon,
  loading,
  withAdditionalValue,
}) => {
  return loading ? (
    <Skeleton>
      <FiltersItem label={label} />
    </Skeleton>
  ) : (
    <Accordion
      sx={{
        borderRadius: '0',
        boxShadow: 'none',
        '&.Mui-expanded': {
          margin: '0',
        },
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          padding: '0',
          minHeight: '40px',
          alignItems: 'flex-start',
          '&.Mui-expanded': {
            minHeight: 'auto',
          },
          '& .MuiAccordionSummary-content': {
            order: '2',
            margin: 0,
            alignItems: 'center',
            '&.Mui-expanded': {
              margin: 0,
            },
            '& .MuiFormControlLabel-root': {
              margin: '0 8px 0 0',
            },
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            order: '1',
            transform: 'rotate(-90deg)',
            mt: '10px',
            '&.Mui-expanded': {
              transform: 'rotate(0deg)',
            },
            opacity: hiddenIcon ? '0' : '1',
            cursor: hiddenIcon ? 'default' : 'pointer',
          },
        }}
        expandIcon={
          <SimpleArrowDownwardIcon
            width={20}
            height={20}
            viewBox='0 0 24 24'
            color='rgba(34, 34, 34, 1)'
          />
        }
        aria-controls='panel1a-content'
        id={id}
      >
        <FiltersItem
          disabled={disabled}
          isPartiallyChecked={isPartiallyChecked}
          label={label}
          additionalValue={additionalValue}
          checked={checked}
          onChange={onChange}
          withAdditionalValue={withAdditionalValue}
        />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '40px',
          }}
        >
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
