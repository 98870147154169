import {
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  TextFieldProps,
  useFormControl,
} from '@mui/material'
import { ClearIcon, ReturnIcon, SearchIcon } from 'icons'
import React, { FC } from 'react'

import { AddCloseIconProps, SearchProps } from './SearchComponent.types'

const ChangeableSearchIcon = () => {
  const { filled, focused } = useFormControl() || {}

  const color = React.useMemo(() => {
    if (filled || focused) {
      return '#009970'
    }

    return 'rgba(34, 34, 34, 0.48)'
  }, [filled || focused])

  return (
    <InputAdornment position='start'>
      <SearchIcon width={24} height={24} color={color} />
    </InputAdornment>
  )
}

const AddCloseIcon: FC<AddCloseIconProps> = ({ onClick, isEnterIcon }) => {
  const { filled } = useFormControl() || {}

  const display = React.useMemo(() => {
    if (filled) {
      return 'flex'
    }

    return 'none'
  }, [filled])

  return (
    <InputAdornment position='end' sx={{ display: { display } }}>
      <IconButton
        onClick={onClick}
        sx={{ ':hover': { background: 'transparent' } }}
      >
        {isEnterIcon ? (
          <ReturnIcon width={24} height={24} color='rgba(34, 34, 34, 0.48)' />
        ) : (
          <ClearIcon width={24} height={24} color='rgba(34, 34, 34, 0.48)' />
        )}
      </IconButton>
    </InputAdornment>
  )
}

export const SearchComponent: FC<SearchProps & TextFieldProps> = ({
  value,
  onChange,
  placeholder,
  width = '536px',
  marginRight,
  marginLeft,
  onClearClick,
  loading,
  withEnterIcon,
  onEnterClick,
  error,
  helperText,
  ...props
}) => {
  return (
    <>
      {loading ? (
        <Skeleton
          variant={'rectangular'}
          sx={{
            width: '100%',
            minWidth: '258px',
            maxWidth: width,
            marginRight,
            marginLeft,
            height: '48px',
            borderRadius: '24px',
            ...props.sx,
          }}
        />
      ) : (
        <TextField
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          helperText={helperText}
          sx={{
            width: '100%',
            minWidth: '258px',
            maxWidth: width,
            marginRight,
            marginLeft,
            '& .MuiOutlinedInput-root': {
              height: '48px',
              borderRadius: '24px',
              '&:hover': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey.300',
                },
              },
              '&:active': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey.400',
                },
              },
              '&.Mui-focused': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey.300',
                  borderWidth: '1px',
                },
              },
              '.MuiInputBase-input': {
                padding: '12.5px 14px 12.5px 6px',
                '&:-webkit-autofill': {
                  WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                },
              },
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'grey.200',
            },
            ...props.sx,
          }}
          InputProps={{
            style: {
              width: '100%',
              color: '#222222',
            },
            startAdornment: <ChangeableSearchIcon />,
            endAdornment: (
              <AddCloseIcon
                isEnterIcon={withEnterIcon}
                onClick={withEnterIcon ? onEnterClick : onClearClick}
              />
            ),
          }}
          {...props}
        />
      )}
    </>
  )
}
